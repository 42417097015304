import React, { useState } from 'react'
import { FieldErrors } from 'react-hook-form/dist/types'
import { FormattedMessage } from 'react-intl'
import Next from '../../../assets/images/Talleres/next.svg'
import { t } from '../../../../common/i18n'
import Size from '../../../utils/media-queries-setup'
import route from '../../../utils/route'
import Button from '../../buttons/Button'
import CestaLastRow from '../../cesta/cestaItem/cestaLastRow/CestaLastRow'
import CheckBoxYellowCesta from '../../checkboxYellowCesta/CheckBoxYellowCesta'
import Error from '../../error/Error'
import FormError from '../../FormError/FormError'
import InputCesta from '../../inputCesta/InputCesta'
import InputCestaDropDown from '../../inputCesta/InputCestaDropDown'
import styles from './index.module.scss'

export interface InContactForm {
  name: string
  email: string
  phone: string
  postCode: string
  coment: string
  checkboxPrivacy: string
  idOrder: string
  matricula: string
  service: string
  vehicle: string
}

export type RegisterFormErrors = FieldErrors<InContactForm>

interface Props {
  goSelectedForm: (selected: string) => void
  onGoBack?: () => void
  tipo: string
  refe: any
  err: RegisterFormErrors
  handle?: (submit: any) => void
  onSubmit?: (data: any) => void
  watch?: (data: any) => void
  globalError: string | null
}

const vehcleOptions = [
  { value: '', label: t('contacto.options.seleccionar') },
  { value: 'coche', label: t('contacto.options.coche') },
  { value: 'moto', label: t('contacto.options.moto') },
  { value: 'camion', label: t('contacto.options.camion') },
  { value: 'agricola', label: t('contacto.options.agricola') },
]

const optionsCoche = [
  { value: 'neumaticos', label: t('contacto.options.neumaticos') },
  { value: 'revision Pre ITV', label: t('contacto.options.revision') },
  { value: 'mantenimiento', label: t('contacto.options.mantenimiento') },
  { value: 'mecanica', label: t('contacto.options.mecanica') },
  { value: 'frenos', label: t('contacto.options.frenos') },
  { value: 'electricidad', label: t('contacto.options.elec') },
  { value: 'climatizacion', label: t('contacto.options.clima') },
  { value: 'diagnosis', label: t('contacto.options.diagnosis') },
]

const optionsMoto = [
  { value: 'neumaticos', label: t('contacto.options.neumaticos') },
  { value: 'revision Pre ITV', label: t('contacto.options.revision') },
  { value: 'mantenimiento', label: t('contacto.options.mantenimiento') },
  { value: 'mecanica', label: t('contacto.options.mecanica') },
  { value: 'frenos', label: t('contacto.options.frenos') },
  { value: 'electricidad', label: t('contacto.options.elec') },
  { value: 'transmision', label: t('contacto.options.trans') },
  { value: 'accesorios', label: t('contacto.options.accesorios') },
]

const optionsCamion = [
  { value: 'mantenimiento', label: t('contacto.options.mantenimiento') },
  { value: 'electricidad', label: t('contacto.options.elec') },
  { value: 'frenos', label: t('contacto.options.frenos') },
  { value: 'suspension y direccion', label: t('contacto.options.susp') },
  { value: 'climatizacion', label: t('contacto.options.clima') },
  { value: 'neumaticos', label: t('contacto.options.neumaticos') },
  { value: 'Gestión de flotas', label: t('contacto.options.flotas') },
  { value: 'asistencias', label: t('contacto.options.asist') },
]
const optionsEmpty = [{ value: '', label: t('contacto.options.seleccionar') }]

export default function FormContacto({
  goSelectedForm,
  onGoBack,
  tipo,
  refe,
  err,
  handle,
  onSubmit,
  globalError,
  watch,
}: Props) {
  const [selectedVehicle, setSelectedVehicle] = useState('')

  const handleChange = data => {
    setSelectedVehicle(data)
  }

  return (
    <React.Fragment>
      <div className={styles.form_container}>
        <p className={styles.intro_text}>
          <FormattedMessage id="registerForm.intro_text" />
        </p>
        <InputCesta
          label={<FormattedMessage id="registerForm.name" />}
          name="name"
          type="text"
          inputSize="big"
          refe={refe}
          errors={err}
        />
        <InputCesta
          label={<FormattedMessage id="registerForm.email" />}
          name="email"
          inputSize="big"
          type="text"
          refe={refe}
          errors={err}
        />

        <InputCesta
          label={<FormattedMessage id="registerForm.phone" />}
          name="phone"
          type="text"
          refe={refe}
          errors={err}
        />
        {tipo === 'taller' ? (
          <InputCesta
            label={<FormattedMessage id="registerForm.postCode" />}
            name="postCode"
            type="text"
            refe={refe}
            errors={err}
          />
        ) : null}

        {tipo === 'pedidos' ? (
          <div>
            <p className={styles.intro_text}>
              <FormattedMessage id="contacto.contacto.comanda" />
            </p>
            <InputCesta
              label={<FormattedMessage id="registerForm.idOrder" />}
              name="idOrder"
              type="text"
              refe={refe}
              errors={err}
            />
          </div>
        ) : null}

        {tipo === 'presupuesto' ? (
          <div>
            <p className={styles.intro_text}>
              <FormattedMessage id="contacto.contacto.vehiculo" />
            </p>

            <InputCestaDropDown
              labelId="contacto.contacto.vehiculoLabel"
              size="normal"
              name="vehicle"
              formRef={refe}
              onChange={e => handleChange(e.target.value)}
              errors={err}
              options={vehcleOptions}
              labelAbove={false}
            />

            <InputCestaDropDown
              labelId="contacto.contacto.serviciosLabel"
              size="normal"
              name="service"
              formRef={refe}
              labelAbove={false}
              errors={err}
              options={
                selectedVehicle === 'coche'
                  ? optionsCoche
                  : selectedVehicle === 'moto'
                  ? optionsMoto
                  : selectedVehicle === 'camion' ||
                    selectedVehicle === 'agricola'
                  ? optionsCamion
                  : optionsEmpty
              }
              disabled={selectedVehicle === ''}
            />
            <InputCesta
              label={<FormattedMessage id="registerForm.matricula" />}
              name="matricula"
              type="text"
              refe={refe}
              errors={err}
            />
          </div>
        ) : null}

        <div className={styles.inputCesta_wrapper}>
          <label className={styles.label}>
            {<FormattedMessage id="registerForm.coment" />}
          </label>
          <div>
            <textarea
              defaultValue={''}
              name="coment"
              ref={refe}
              className={styles.text}></textarea>
            {typeof err === 'string' ? (
              err && <Error message={err}></Error>
            ) : (
              <FormError errors={err} field="coment" />
            )}
          </div>
        </div>
        <div className={styles.checkbox_container}>
          <div className={styles.checkboxYellow_wrapper}>
            <CheckBoxYellowCesta
              label={
                <div className={styles.label}>
                  <FormattedMessage id="registrado.acepto" />
                </div>
              }
              name="checkboxSpam"
              refe={refe}
            />
          </div>
          <div className={styles.checkboxYellow_wrapper}>
            <CheckBoxYellowCesta
              label={
                <div className={styles.label}>
                  <span>
                    <FormattedMessage id="registerForm.checkbox-privacy-read" />
                  </span>
                  <a
                    className={styles.spanlabel}
                    href={route('politica-privacidad.index')}
                    target="_blank"
                    rel="noopener noreferrer">
                    <FormattedMessage id="registerForm.checkbox-privacy-policy" />
                  </a>
                </div>
              }
              name="checkboxPrivacy"
              refe={refe}
            />
            <FormError errors={err} field="checkboxPrivacy" />
            {globalError && <Error message={globalError} />}
          </div>
        </div>
      </div>
      <Size.Matcher
        desktop={
          <CestaLastRow
            onSendClick={() => handle(onSubmit)}
            onBackClick={onGoBack}></CestaLastRow>
        }
        mobile={
          <div className={styles.button_cont}>
            <Button buttonClass="next_button" onClick={() => handle(onSubmit)}>
              <FormattedMessage id="registerForm.enviar" />
              <Next />
            </Button>
          </div>
        }
      />
    </React.Fragment>
  )
}
