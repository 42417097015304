import { navigate } from 'gatsby'
import React, { useReducer, useState } from 'react'
import useForm from 'react-hook-form'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { t } from '../../../../common/i18n'
import * as api from '../../../api/user'
import { SeoData } from '../../../types/Seo'
import {
  fullContactFields,
  IFullContactFields,
  IMinContactFields,
  IpreContactFields,
  minContactFields,
  preContactFields,
} from '../../../utils/contactValidatios'
import route from '../../../utils/route'
import { alertProp } from '../../../utils/swal'
import FormContacto from '../../contacto/FormContacto/FormContacto'
import SelectorMotivo from '../../contacto/seleccionaMotivo/SelectorMotivo'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import Layout from '../../Layout'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import MainFormContainer from '../../layouts/mainFormContainer/MainFormContainer'
import SpinnerWithText from '../../spinnerWithText/SpinnerWithText'
import styles from './ContactoPage.module.scss'
import { normalizeMatricula } from '../../../constants/matricula'

const RegisterSchema = yup.object().shape(minContactFields)
const RegisterSchemaFull = yup.object().shape(fullContactFields)
const RegisterSchemaPre = yup.object().shape(preContactFields)

export interface ContactoProps {
  path: string
  gotoContact: (token) => void
  seoData: SeoData
}

const initialState = {
  globalError: null as string | null,
  success: false as boolean,
  loading: false as boolean,
  callbackError: null as string | null,
}
type State = typeof initialState

function reducer(state: State, action) {
  switch (action.type) {
    case 'SET_CONTACTAR':
      return {
        ...state,
        loading: true,
        globalError: null,
      }
    case 'SET_CONTACTAR_OK':
      return {
        ...state,
        loading: false,
        success: true,
        globalError: null,
      }
    case 'SET_CONTACTAR_FAILED':
      return {
        ...state,
        loading: false,
        success: false,
        globalError: action.payload.error,
      }
    case 'RESET_SUCCESS':
      return {
        ...state,
        success: false,
      }
    case 'SET_CALLBACK_ERRORS':
      return {
        ...state,
        loading: false,
        success: false,
      }
  }
}

export default function Contacto({
  path,
  gotoContact,
  seoData,
}: ContactoProps) {
  const [localState, localDispatch] = useReducer(reducer, initialState)
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearError,
    triggerValidation,
  } = useForm<IMinContactFields>({
    mode: 'onSubmit',
    validationSchema: RegisterSchema,
  })
  const {
    register: registerFull,
    handleSubmit: handleSubmitFull,
    errors: errorsFull,
    setError: setErrorFull,
    clearError: clearErrorFull,
    triggerValidation: triggerValidationFull,
  } = useForm<IFullContactFields>({
    mode: 'onSubmit',
    validationSchema: RegisterSchemaFull,
  })
  const {
    register: registerPre,
    handleSubmit: handleSubmitPre,
    errors: errorsPre,
    watch: watchPre,
    setError: setErrorPre,
    clearError: clearErrorPre,
    triggerValidation: triggerValidationPre,
  } = useForm<IpreContactFields>({
    mode: 'onSubmit',
    validationSchema: RegisterSchemaPre,
  })
  const [step, setStep] = useState('contacto')

  const goToPreviousStep = () => {
    clearErrorPre()
    clearErrorFull()
    clearError()
    if (step === 'contacto') {
      navigate(route('index'))
      return null
    }
    if (step !== 'contacto') {
      setStep('contacto')
      return null
    }
  }

  const goSelectedForm = (stepSelected) => {
    if (stepSelected === '') {
      return Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: t('contacto.error_step'),
        })
      )
    }
    setStep(stepSelected)
  }
  const onSubmit = (data) => {
    data.motivo = step
    if (data.matricula) {
      data.matricula = normalizeMatricula(data.matricula)
    }
    localDispatch({
      type: 'SET_CONTACTAR',
    })
    api
      .sendContacto(data)
      .then((res) => {
        if (res.errors) {
          res.errors.forEach((e) => {
            setError(e.field, e.type, t(e.message))
            setErrorFull(e.field, e.type, t(e.message))
            setErrorPre(e.field, e.type, t(e.message))
          })
          localDispatch({
            type: 'SET_CALLBACK_ERRORS',
          })
          return
        }
        localDispatch({
          type: 'SET_CONTACTAR_OK',
        })
      })
      .catch((err) => {
        localDispatch({
          type: 'SET_CONTACTAR_FAILED',
          payload: {
            error: t('contacto.error'),
          },
        })
      })
  }

  const onPedidosFormChange = (e) => {
    const target = e.target as HTMLInputElement
    clearError(target.name as keyof IMinContactFields)
    triggerValidation({
      name: target.name as keyof IMinContactFields,
    })
  }

  const onPresupuestoFormChange = (e) => {
    const target = e.target as HTMLInputElement
    clearErrorPre(target.name as keyof IpreContactFields)
    triggerValidationPre({
      name: target.name as keyof IpreContactFields,
    })
  }

  const onTallerFormChange = (e) => {
    const target = e.target as HTMLInputElement
    clearErrorFull(target.name as keyof IFullContactFields)
    triggerValidationFull({
      name: target.name as keyof IFullContactFields,
    })
  }

  if (localState.success) {
    localDispatch({
      type: 'RESET_SUCCESS',
    })
    gotoContact(true)
  }

  return (
    <Layout seoData={seoData} selector={true} onGoBack={goToPreviousStep}>
      <LayoutSelector hideSelectorSteps subTitle={t('contacto.title')}>
        <MainFormContainer>
          {localState.loading ? <SpinnerWithText text="" size={200} /> : null}
          {step === 'contacto' && !localState.loading ? (
            <SelectorMotivo
              goSelectedForm={goSelectedForm}
              onGoBack={goToPreviousStep}
            />
          ) : null}
          {step === 'pedidos' ? (
            <form
              onChange={onPedidosFormChange}
              className={localState.loading ? styles.hidden : ''}
              onSubmit={handleSubmit(onSubmit)}>
              <FormContacto
                goSelectedForm={goSelectedForm}
                onGoBack={goToPreviousStep}
                tipo={step}
                refe={register}
                err={errors}
                onSubmit={onSubmit}
                handle={handleSubmit}
                globalError={localState.globalError}
              />
            </form>
          ) : null}
          {step === 'atencion' ? (
            <form
              onChange={onPedidosFormChange}
              className={localState.loading ? styles.hidden : ''}
              onSubmit={handleSubmit(onSubmit)}>
              <FormContacto
                goSelectedForm={goSelectedForm}
                onGoBack={goToPreviousStep}
                tipo={step}
                refe={register}
                err={errors}
                onSubmit={onSubmit}
                handle={handleSubmit}
                globalError={localState.globalError}
              />
            </form>
          ) : null}
          {step === 'presupuesto' ? (
            <form
              onChange={onPresupuestoFormChange}
              className={localState.loading ? styles.hidden : ''}
              onSubmit={handleSubmitPre(onSubmit)}>
              <FormContacto
                goSelectedForm={goSelectedForm}
                onGoBack={goToPreviousStep}
                tipo={step}
                refe={registerPre}
                err={errorsPre}
                onSubmit={onSubmit}
                handle={handleSubmit}
                globalError={localState.globalError}
                watch={watchPre}
              />
            </form>
          ) : null}
          {step === 'taller' ? (
            <form
              onChange={onTallerFormChange}
              className={localState.loading ? styles.hidden : ''}
              onSubmit={handleSubmitFull(onSubmit)}>
              <FormContacto
                goSelectedForm={goSelectedForm}
                onGoBack={goToPreviousStep}
                tipo={step}
                refe={registerFull}
                err={errorsFull}
                onSubmit={onSubmit}
                globalError={localState.globalError}
                handle={handleSubmit}
              />
            </form>
          ) : null}
        </MainFormContainer>
        <FunnelSideBar />
      </LayoutSelector>
    </Layout>
  )
}
